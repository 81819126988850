import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h5>{`Future improvements`}</h5>
    <p>{`I already have a lot ideas of how to fit the artworks better to a track.
Some of the ideas include:`}</p>
    <ul>
      <li parentName="ul">{`Being able to select genres or other categories related to the music style.`}</li>
      <li parentName="ul">{`Choose colours that you want to have in the artwork.`}</li>
      <li parentName="ul">{`Have multiple versions created at the same time and let the user pick the one they like most.`}</li>
      <li parentName="ul">{`Upload the track, analyse it and create the artwork based on that data.`}</li>
    </ul>
    <p><strong parentName="p">{`Ressources:`}</strong></p>
    <ul>
      <li parentName="ul">{`Link to the project code: `}<a parentName="li" {...{
          "href": "https://github.com/lItc0de/artwork-generator"
        }}>{`https://github.com/lItc0de/artwork-generator`}</a></li>
      <li parentName="ul">{`Link to the project website: `}<a parentName="li" {...{
          "href": "https://artwork-generator.litc0.de/"
        }}>{`https://artwork-generator.litc0.de`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      